// Common

import {
  OptionalPackage,
  OptionalPackageStatus,
  WebPricePackageRecurring,
} from 'service/payment';
import { PolicyError, PolicyLevel } from 'service/post';
import { ChartSymbolAlertData } from 'service/chart';
import { Mission, TopicType } from 'service/user';
import { CourseInfo } from 'service/course';
import { CharacterStageId, UserCharacter } from 'service/anime';
import { InvestmentLevel } from 'service/investment_level';

export enum Emotion {
  Happy = 'happy',
  Sad = 'sad',
  Tired = 'tired',
  Excited = 'excited',
  Chilling = 'chilling',
}

export enum TipEmotion {
  Tip1 = 'tip1',
  Tip2 = 'tip2',
  Tip3 = 'tip3',
  Tip4 = 'tip4',
  Tip5 = 'tip5',
}

export enum PostActionEmotion {
  Share = 'share-new',
  Like = 'like',
  Comment = 'comment',
  Follow = 'follow',
  Subscribe = 'subscribe',
  SubscribePlus = 'subscribe-plus',
  CreatePost = 'new-post',
  SendTip = 'send-tip',
  ProfileView = 'profile-view',
  TrendingPost = 'trending-post',
  InvitationCodeReferred = 'invitation-code-referred',
  InvitationCodeNewUser = 'invitation-code-referred',
  HostGoLive = 'live',
  PurchasedCourse = 'purchase-course',
}

export enum Badge {
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5',
  Level6 = 'level6',
  Level7 = 'level7',
  Level8 = 'level8',
  Level9 = 'level9',
  Level10 = 'level10',
}
export type BadgeKey = keyof typeof Badge;
export type FreeOption = {
  value: number;
  active: boolean;
};

export enum UserPopupTypeDefine {
  PopupTypeViolationAI = 'investment_violation',
  PopupTypeCopyrightViolationAI = 'copyright_violation',
  PopupTypeViolenceViolationAI = 'violence_violation',
  PopupTypeInvestmentViolationFree = 'investment_violation_change_to_free',
  PopupTypeAdultViolationFree = 'adult_violation_change_to_free',
  PopupTypeViolenceViolationFree = 'violence_violation_change_to_free',
  PopupTypeCopyrightViolationFree = 'copyright_violation_change_to_free',
  PopupTypeInvestmentViolationDisable = 'investment_violation_change_to_disabled',
  PopupTypeAdultViolationDisable = 'adult_violation_change_to_disabled',
  PopupTypeViolenceViolationDisable = 'violence_violation_change_to_disabled',
  PopupTypeCopyrightViolationDisable = 'copyright_violation_change_to_disabled',
  PostCopyRightViolation = 'post_copyright_violation',
  PostChangedToActivated = 'post_changed_to_activated',
  PostChangedToPaid = 'post_changed_to_paid',
  PostChangedToPaidPlus = 'post_changed_to_paid_plus',
  PostAdvertisementViolation = 'post_advertisement_violation',
}

export enum UserPopupCampaignDefine {
  DiscountCampaign = 'year_end_2024_prime_subscription',
  DiscountCampaignCreator = 'year_end_2024_prime_subscription_creator',
  DiscountMembershipCampaign = 'year_end_2024_membership',
  CoinBackCampaign = 'coin_after_subscribe_promotion',
  IGAccountSpecialRegister = 'ig_account_special_register',
  Event202507 = '2025_july_event_v2',
}

export enum UserPopupAlertDefine {
  PostOnboardPopup = 'post_onboarding_popup',
  InputIsAdult = 'input_is_adult',
  PrimePlusNoticeCreator = 'prime_plus_notice_creator',
  IzanaviPR = 'izanavi_popup',
  MissionsPRPopup = 'shouken_company_mission_popup',
  ConfirmPaymentPopup = 'confirm_payment',
}

export interface UserPopup {
  id: number;
  type: UserPopupTypeDefine | UserPopupCampaignDefine | UserPopupAlertDefine;
  level: PolicyLevel;
  precheck_logs?: PrecheckLog[];
  reason: string;
  post_id: number;
  missions?: Mission[];
  confirm_payment_info?: {
    package_name: string;
    amount: number;
    subscription_type: string;
    invoice_url: string;
    username: string;
  };
}

export enum RoleStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Untreated = 'untreated',
  Canceled = 'canceled',
}

export interface User {
  id: number;
  name: string;
  username: string;
  badge: Badge;
  emotion?: Emotion;
  bio?: string;
  avatar_url?: string;
  is_followed: boolean;
  is_subscribed: boolean;
  is_trialing?: boolean;
  job: string;
  contact_email: string;
  follow_count: string;
  interaction_rate: number;
  engagement_rate: number;
  is_creator: boolean;
  is_advertiser: boolean;
  is_able_to_be_creator: boolean;
  // TODO: maybe move these to another interface
  blocked_at?: string;
  reported_at?: string;
  reason?: string;
  rating_system_is_on?: boolean;
  price?: number;
  created_at?: string;
  // paid users screen
  subscribed_at?: string;
  // following users screen
  followed_at?: string;
  has_new_post?: boolean;
  on_boarding?: boolean;
  need_new_onboarding?: boolean;
  // true => need to show onboarding anime
  anime_character_onboarding?: boolean;
  anime_character_introduction?: boolean;
  subscription_ended_at?: string;
  country: Country;
  updated_username: boolean;
  email_setting_news_letter: boolean;
  // for newsletter setting
  email_setting_creator_notice?: boolean;
  email_setting_event_notice?: boolean;
  email_setting_campaign_notice?: boolean;
  provider: string;
  setting: {
    free_user_max_file_size: number;
    creator_max_file_size: number;
    creator_max_audio_file_size: number;
    course_max_video_file_size: number;
    creator_max_image_file_size: number;
    creator_max_video_file_size: number;
    user_max_audio_file_size: number;
    user_max_image_file_size: number;
    user_max_video_file_size: number;
  };
  latest_paid_post_published_at?: string;
  latest_plus_paid_post_published_at?: string;
  requested_account_deletion?: boolean;
  applicable_promotions?: IntroEvent[];
  // intro reward
  referral_token?: number;
  year_of_birth: number;
  sex: number; // [0:未回答 1男性 2女性]
  hobbies: Hobby[];
  domains: TopicType[];
  investment_level: InvestmentLevel;
  job_type: JobType;
  is_show_popup_for_detail_form: boolean;
  get_notifications_type?: 'all' | 'post' | null;
  android_price: number;
  subscription_platform:
    | 'credit'
    | 'ios_iap'
    | 'android_iap'
    | 'web_coin'
    | 'android_coin'
    | 'ios_coin';
  ios_price: number;
  role: 'user' | 'admin' | 'operator' | 'middle_admin' | 'master_admin';
  has_tipped: null | number;
  is_open_for_subscription?: boolean;
  is_email_verified?: boolean;
  // coin: {
  //   sum: number;
  //   expiring_coins: ExpiringCoin[];
  //   consumable_coin_total: number;
  //   pending_coin_total: number;
  //   upcoming_coin_amount: number;
  //   during_campaign_coin_amount: number;
  //   membership_upcoming_coin_item: { name: string; coin_amount: number } | null;
  //   prime_subscription_upcoming_coin_items:
  //     | [{ name: string; coin_amount: number }]
  //     | null;
  // };
  phone_number?: string;
  in_live?: boolean;
  has_unread_post?: boolean;
  live_webcam?: boolean;
  live_software?: boolean;
  can_create_gold_post?: boolean;
  is_referral_code_registered?: boolean;
  has_live_permission?: boolean;
  is_create_course_able?: boolean;
  is_turn_on_notification_btn?: boolean;
  is_subscribed_membership_before: boolean;
  is_membership_subscription_ready: boolean;
  is_disabled_dashboard?: boolean;
  free_options: FreeOption[];
  is_subscribed_before?: boolean;
  expand_membership_statuses: {
    expand_membership_package: MembershipExtendedPackage;
    is_interval: boolean;
    is_ready_for_subscription: boolean;
    is_subscribed_before: boolean;
    is_subscribing: boolean;
    is_trialing: boolean;
    end_at: string;
  }[];
  membership?: {
    membership_package?: MembershipPackage;
    next_membership_package?: MembershipPackage;
    subscription_end_at?: string;
    is_trialing?: boolean;
    is_interval?: boolean;
    next_package_name?: MembershipPackageName;
    next_package_change_timing?: string;
    subscription_payment_type:
      | 'credit'
      | 'ios_iap'
      | 'android_iap'
      | 'web_coin'
      | 'android_coin'
      | 'ios_coin';
    coupon?: {
      coupon_end_at: string;
      promotion_type: string;
    };
  };
  // for paid user api
  recurring?: WebPricePackageRecurring;
  creator_subscription: {
    current_plan: WebPricePackageRecurring;
    ended_at: null;
    platform:
      | 'credit'
      | 'ios_iap'
      | 'android_iap'
      | 'web_coin'
      | 'android_coin'
      | 'ios_coin';
    status: 'active' | 'trialing' | 'canceled' | 'unpaid';
  };
  // creator able to enable subscription
  is_long_subscription_available: boolean;
  // is creator supporting long subscription
  is_enabled_long_subscription_setting: boolean;
  need_first_onboarding?: boolean;
  rbac_roles: {
    [attr: string]: {
      name: string;
      description: string;
      status: RoleStatus | null;
    };
  };
  user_character: {
    character: UserCharacter;
    stage: {
      id: CharacterStageId;
      name: string;
    };
  } | null;
  feature_flags: {
    feature_flags: { name: string; active: boolean }[];
  };
  // creator popup
  is_turn_off_one_more_subscription_popup: boolean;
  joined_promotions: IntroEvent[];
  subscribed_during_promotions: IntroEvent[];
  is_adult?: boolean;
  birthday?: string;
  option_subscription_prices: OptionalPackage[];
  option_creator_subscriptions: OptionalPackageStatus[];
}

export enum SortType {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ProfilePageTab {
  PostsList = 'posts',
  SavedPosts = 'saved_posts',
  ProfileInput = 'profile',
}

export enum CoursePageTab {
  AllCourses = 'all_courses',
  MyCourses = 'my_courses',
}

export type PaidUserType = User & { is_unqualified_subscription: boolean };

export type PostContentParams = {
  text: string;
  type: PostType;
  media: Media[];
  published_at: string | null;
  domain_ids: number[];
  symbol_ids: number[];
  free_minutes: number;
  streaming_id?: number;
  investment_level_ids: number[];
  post_kind_id: number;
};

export type MembershipBenefit = {
  order: number;
  icon_link: string;
  text: string;
  sub_text: string;
};

export enum MembershipPackageRecurring {
  Month = 'month',
  HalfYear = 'half_year',
  Year = 'year',
}

export enum MembershipPackageExtendedPackageName {
  Izanavi = 'izanavi',
}
export interface MembershipExtendedPackage {
  id: number;
  package_name: MembershipPackageExtendedPackageName;
  web_amount: number;
  subscription_limitation: number;
  minimum_amount: number;
  discounted_percentage: number;
  discounted_web_amount: number;
}

export type MembershipPackage = {
  id: number;
  web_amount: number;
  ios_amount: number;
  android_amount: number;
  product_id: string;
  ios_product_id: string;
  android_product_id: string;
  level: 1 | 2 | 3;
  prime_subscription_discount_percentage: number;
  package_name: MembershipPackageName;
  item: MembershipBenefit[];
  recurring: MembershipPackageRecurring;
  free_trial_days: number;
  discounted_web_amount: number;
  expand_membership_packages: MembershipExtendedPackage[];
  minimum_amount: number;
};

export enum MembershipPackageName {
  Green = 'green-membership',
  Silver = 'silver-membership',
  Gold = 'gold-membership',
  Platinum = 'platinum-membership',
  Izanavi = 'platinum-izanavi-membership',
}

export type ExpiringCoin = {
  coin_amount: number;
  end_at: string;
};

export type IntroEvent = {
  type: string;
  start_at: string;
  end_at: string;
  status?: 'approved' | 'canceled' | null;
  params: {
    referral_reward_max_times?: number;
    referral_reward_new_user?: number;
    referral_reward_referred_user?: number;
    ab_test_group?: string;
    // discount campaign
    current_subscription?: number;
    max_subscription?: number;
    // coin back campaign
    gold_membership_coin_back?: number;
    green_membership_coin_back?: number;
    platinum_membership_coin_back?: number;
    prime_subscription_coin_back?: number;
    silver_membership_coin_back?: number;
    total_coin?: number;
    channel_price?: number;
    web_price?: number;
  };
};

export interface Country {
  name: string;
  code: string;
}

export interface Banner {
  id: number;
  type:
    | 'general_notification'
    | 'agreement_notification'
    | 'fixed_notification';
  text: string;
  url: string;
  severity?: 'warning' | 'info' | 'error';
}

export interface GetBannersResult {
  data: Banner[];
}

// Trending posts

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Link = 'link',
  Audio = 'audio',
  Stream = 'stream',
}

export interface Media {
  data: {
    url?: string;
    width?: number;
    height?: number;
    thumbnail_url?: string;
    title?: string;
    source_name?: string;
    spectrum?: number[];
    duration?: number;
    blur?: boolean;
    free_version?: string;
  };
  type: MediaType;
  // when need to upload, save mediaFile here
  file?: File;
}

export enum PostType {
  Free = 'free',
  Paid = 'paid',
  // affiliate is only use for create/edit post
  Affiliate = 'affiliate',
  Gold = 'gold',
  Plus = 'paid_plus',
}

export enum StreamType {
  Software = 'software',
  Webcam = 'webcam',
}

export enum PostState {
  Disable = 'disabled',
  OnProcessing = 'on_processing',
  Activated = 'activated',
}

export interface PostSource {
  type: string;
  name: string;
  version: string;
}

export interface PostKind {
  id: number;
  kind: string;
  name: string;
}

export interface Post {
  id: number;
  text: string;
  like_count: number;
  save_count: number;
  comment_count: number;
  share_count: number;
  is_saved: boolean;
  is_liked: boolean;
  is_shared: boolean;
  media: Media[];
  user_info: User;
  created_at: string;
  published_at: string;
  is_subscription: boolean;
  // TODO: remove this field.
  is_my_post: boolean;
  is_pinned?: boolean;
  view_count: number;
  is_blocked: boolean;
  type: PostType;
  // TODO: maybe move these to another interface
  reported_at: string;
  status: PostState;
  is_affiliate: boolean;
  // intro reward
  referral_token?: number;
  content_access?: {
    is_authorized: boolean;
    reason: string; // referral_token
  };
  precheck_logs?: PrecheckLog[];
  domains?: TopicType[];
  topics?: string[];
  user_tipping?: TippedInfoDetail[];
  symbols?: SymbolChart[];
  free_minutes?: number;
  streaming?: LiveStream;
  is_onboarding_free_paid_post?: boolean;
  source?: PostSource;
  streaming_info?: {
    delivery_started_at: string;
  };
  investment_levels?: InvestmentLevel[];
  post_kind?: PostKind;
}

export type PrecheckLog = {
  type: string;
  reasons: PolicyError[];
};

export type MyPostType = Post & {
  latest_popup_type:
    | UserPopupTypeDefine
    | UserPopupCampaignDefine
    | UserPopupAlertDefine
    | null;
  popup_level: PolicyLevel | null;
  popup_reason: string | null;
};

export interface AddressResultType {
  data: {
    zip: string;
    ken_name: string;
    city_name: string;
    town_name: string;
  };
}

export type AddressType = {
  zip_1: string;
  zip_2: string;
  kenName: string;
  cityName: string;
  townName: string;
  roomInfo: string;
};

export enum NotificationType {
  Like = 'like_post',
  Comment = 'comment_post',
  Share = 'share_post',
  Follow = 'follow',
  Subscribe = 'subscribe',
  SubscribePlus = 'subscribe_plus',
  Mention = 'comment_mentions',
  Reply = 'comment_reply',
  CreatePost = 'host_create_post',
  ReSchedulePost = 'host_reschedule_post',
  CancelSchedulePost = 'host_cancel_post',
  SendTip = 'send_tip',
  ProfileView = 'profile_view',
  TrendingPost = 'trending_post',
  InvitationCodeReferred = 'invitation_code_referred',
  InvitationCodeNewUser = 'invitation_code_new_user',
  HostGoLive = 'host_go_live',
  VoteDailyHighAlert = 'vote_daily_high',
  VoteWeeklyHighAlert = 'vote_weekly_high',
  VoteMonthlyHighAlert = 'vote_monthly_high',
  VoteDailyLowAlert = 'vote_daily_low',
  VoteWeeklyLowAlert = 'vote_weekly_low',
  VoteMonthlyLowAlert = 'vote_monthly_low',
  PostDailyHighAlert = 'post_daily_high',
  PostWeeklyHighAlert = 'post_weekly_high',
  PostMonthlyHighAlert = 'post_monthly_high',
  PostDailyLowAlert = 'post_daily_low',
  PostWeeklyLowAlert = 'post_weekly_low',
  PostMonthlyLowAlert = 'post_monthly_low',
  VoteChangeDailyHighAlert = 'vote_change_daily_high',
  VoteChangeWeeklyHighAlert = 'vote_change_weekly_high',
  VoteChangeMonthlyHighAlert = 'vote_change_monthly_high',
  VoteChangeDailyLowAlert = 'vote_change_daily_low',
  VoteChangeWeeklyLowAlert = 'vote_change_weekly_low',
  VoteChangeMonthlyLowAlert = 'vote_change_monthly_low',
  PredictionUserAlarm = 'predict_user_alarm',
  PredictionWeekGtMonth = 'prediction_week_gt_month',
  PredictionMonthGtWeek = 'prediction_month_gt_week',
  PurchasedCourse = 'purchased_course',
  NoticeCampaignToUser = 'notice_campaign_to_user',
  IzanaviSell = 'izanavi_sell',
  IzanaviBuy = 'izanavi_buy',
}

export interface Notification {
  id: number;
  type: NotificationType;
  target_id: number;
  is_read: boolean;
  user_info: User;
  post?: Post;
  comment?: PostComment;
  mentions?: Mention[];
  created_at: string; // FIXME
  coin_amount?: number;
  symbol: SymbolChart;
  title?: string;
  body?: string;
  course?: CourseInfo;
  promotion_type?: string;
  url?: string;
  profile_view?: {
    count: number;
    profile_viewers: User[];
  };
}

export interface GetTrendingPostsResult {
  data: {
    posts: Post[];
  };
}

export interface GetTimelinePostsResult {
  data: {
    posts: Post[];
    next_key?: string;
  };
}

// News

export enum Language {
  Ja = 'ja',
  En = 'en',
}
export interface News {
  id: number;
  language: Language.Ja;
  title: string;
  url: string;
  image: string;
  is_read: boolean;
  source_url: string;
  source_name: string;
  published_date_time: string;
  view_count: number;
}

export interface NewsCategory {
  id: number;
  name: string;
}

export interface GetNewsResult {
  data: { news: News[] };
}

export interface GetNewsCategoriesResult {
  data: NewsCategory[];
}

// Recommended users
export interface GetRecommendedUsersResult {
  data: User[];
}

// User profile

export interface GetCurrentUserProfileResult {
  data: User;
}

export interface GetUserProfileByUsernameResult {
  data: User;
}

// Followers
export interface GetFollowersResult {
  data: User[];
  total?: number;
}

// Subscribers
export interface GetSubscribersResult {
  data: {
    users: User[];
  };
}

// Me
export interface GetMeResult {
  data: User;
}

// Upload media
export interface PostMediaResult {
  data: Media;
}

// Posts

export interface GetPostResult {
  data: Post;
}

export interface GetPostsResult {
  data: {
    posts: Post[];
  };
}

export interface PostAnalytic {
  id: number;
  name: string;
  aggregate_post_data: PostRate[];
  code: string;
  graph_post_data: PostGraphData[];
}

export type VoteGraphData = {
  type: Period;
  data: VoteGraphPoint[];
};

export type PostGraphData = {
  type: Period;
  data: PostGraphPoint[];
};

export interface GetSymbolRelatedPostsResult {
  data: {
    posts: Post[];
    votes?: VoteSymbol[];
    post_analytics: PostAnalytic[];
    post_analytic: PostAnalytic;
    vote: VoteSymbol;
    is_pinned: boolean;
    graph_post_data: PostGraphData[];
    alarm?: ChartSymbolAlertData;
  };
}

export type VoteGraphPoint = {
  total: number;
  target_time: string;
  thumbs_ups_percentage: number;
  thumbs_down_percentage: number;
  graph_percentage?: number;
  percentage_change: number;
};

export type PostGraphPoint = {
  target_time: string;
  post_percentage: number;
  graph_percentage?: number;
};

export interface GetReferralCodeResult {
  data: {
    referral_code: string;
    referred_counts: number;
  };
}

// Get post's comments

export interface PostComment {
  id: number;
  text: string;
  is_liked: boolean;
  like_count: number;
  created_at: string;
  user_info: User;
  reply_count: number;
  mentions?: Mention[];
  post?: Post;
  user_tipping?: TippedInfoDetail;
}

export interface Mention {
  id: number;
  name: string;
  username: string;
}

export interface GetPostCommentsResult {
  data: PostComment[];
}

export interface GetNotificationResult {
  data: Notification[];
}

export enum PaymentMethodStatus {
  None = 'none',
  Setting = 'setting',
  Verifying = 'verifying',
  Verified = 'verified',
}

export interface DashboardHeaderData {
  payment_setup: {
    is_set: boolean;
    price: number;
    status: 'untreated' | 'approved' | 'rejected' | 'ex_creator';
    max_price: number;
    min_price: number;
    able_to_change_price: boolean;
    able_to_change_in_months: number;
    last_price: number;
    last_android_price: number;
    last_ios_price: number;
    last_change?: string;
    payment_method_status: PaymentMethodStatus;
    ios_price: number;
    android_price: number;
    able_to_change_plus_price: boolean;
    plus_price: number;
    last_change_plus_price?: string;
    plus_ios_price: number;
    plus_android_price: number;
    document_status:
      | 'none'
      | 'submitted'
      | 'verifying'
      | 'resubmit'
      | 'verified';
  };
  revenue: {
    last_month_revenue: number;
    increased_revenue: number;
  };
  subscriber: {
    subscribers: number;
    increased_subscribers: number;
  };
  follower: {
    followers: number;
    increased_followers: number;
  };
  view: {
    views: number;
    increased_views: number;
  };
  withholding_setup: {
    country: string;
    status: 'none' | 'submitted' | 'verifying' | 'resubmit' | 'verified';
    countries: Country[];
  };
  upcoming_revenues: UpcomingRevenue[];
  is_disabled: boolean;
  is_enabled_long_subscription: boolean;
  banners?: Banner[];
  is_able_to_resubmit_creator_document: boolean;
  resubmit_status: ResubmitInfoStatus;
  invoice_registration_number: string;
  invoice_registration_number_active: boolean;
  show_invoice_registration_number_remind_popup: boolean;
  joined_promotions: IntroEvent[];
  benefit?: {
    prime_benefit: string | null;
    prime_plus_benefit: string | null;
    status: DocumentStatus;
    draft_prime_benefit: string | null;
    draft_prime_plus_benefit: string | null;
    draft_status: DocumentStatus;
    update_timing: string;
    is_able_to_change: boolean;
    note?: string;
    note_prime_plus?: string;
  };
}
// Dashboard
export interface GetDashboardHeadersResult {
  data: DashboardHeaderData;
}

export enum DocumentStatus {
  None = 'none',
  Submitted = 'submitted',
  Verifying = 'verifying',
  Resubmit = 'resubmit',
  Verified = 'verified',
}

export interface ResubmitInfoStatus {
  document_status: DocumentStatus;
  feedback: string;
}

export type UpcomingRevenue = {
  date: string;
  revenue: number;
};

export type FileType = {
  name: string;
  url: string;
};

export interface GraphData {
  month: string;
  count: number;
}

export interface GetDashboardGraphResult {
  data: {
    [key: string]: GraphData[];
  };
}

export interface GetDashboardLatestPosts {
  data: {
    posts: Post[];
  };
}

// Search

export interface SearchPostsResult {
  data: {
    posts: Post[];
    total_post: number;
    search_session_id: string;
  };
}

export interface SearchUsersResult {
  data: {
    users: User[];
    total_user: number;
    search_session_id: string;
  };
}

// Account settings
export interface GetUsersResult {
  data: {
    users: User[];
  };
  total?: number;
}

export interface GetPaidUsersResult {
  data: {
    users: PaidUserType[];
  };
  total?: number;
}

export interface GetChargesResult {
  data: {
    charges: Charge[];
  };
}

export interface CoinCharge {
  amount: number;
  charge_date: string;
  payment_subscription_id?: number;
  payment_tipping_histories_id: number;
  is_used_for: 'tipping' | 'subscription' | 'membership';
  membership_package?: MembershipPackage;
  subscribed_user?: User;
  tipped_post?: Post;
}

export interface GetCoinChargeResult {
  data: {
    charges: CoinCharge[];
  };
  total?: number;
}

export interface GetPaymentDetailsResult {
  data: {
    payment_details: PaymentDetail[];
  };
  total?: number;
}

export interface PaymentDetail {
  created_at: string;
  paid_out1: string;
  paid_out2: string;
  title: string;
  download_link: string;
}

export interface Charge {
  paid_date: string;
  amount: number;
  payment_method: {
    id: string;
    brand: string;
    last4: string;
  };
  download_link: string;
  charge_id: string;
}

export interface GetSubscriptionInfoResult {
  data: {
    subscribing_users: {
      count: number;
    };
    upcoming_invoice?: {
      amount: number;
      bill_date: string;
    };
    upcoming_coin_invoice?: {
      amount: number;
      bill_date: string;
    };
    upcoming_membership_invoice?: {
      amount: number;
      bill_date: string;
      currency: 'jpy' | 'coin';
    };
    payment_method: {
      id: string;
      brand: string;
      last4: string;
    };
    able_to_delete_payment_method: boolean;
  };
}

export interface GetPostsStatusResult {
  data: PostStatus[];
}

export enum PrecheckStatus {
  SuperHigh = 'super_high',
  High = 'high',
  Medium = 'medium',
  InfluentialCreator = 'influential_creator',
  None = 'none',
}

export interface PostStatus {
  id: number;
  status: string;
  precheck_logs?: PrecheckLog[];
  precheck_status?: PrecheckStatus;
}

export interface GetCountriesListResult {
  data: {
    countries: Country[];
  };
}

export interface Hobby {
  code: string;
  name: string;
}

export interface JobType {
  code: string;
  name: string;
}

export interface GetHobbiesListResult {
  data: Hobby[];
}

export interface GetJobTypesListResult {
  data: JobType[];
}

export interface SharePostURLResult {
  data: {
    share_url: string;
  };
}

// Feeds

type PostWithoutUserInfo = Omit<Post, 'user_info'>;
export interface UserSpotlight {
  user_info: User;
  posts: PostWithoutUserInfo[];
}
export interface GetUserSpotlightsResult {
  data: {
    creator_posts: UserSpotlight[];
  };
}

export interface TrendingTopic {
  topic: string;
}

export interface GetTrendingTopicsResult {
  data: {
    topics: TrendingTopic[];
  };
}

export interface GetTrendingTopicResult {
  data: {
    posts: Post[];
  };
}

export interface GetStripeSetupAccountResult {
  data: string;
}

export interface GetReasonsListResult {
  data: { id: number; text: string }[];
}

export enum PostParentKey {
  Timeline = 'timeline',
  SavePosts = 'save_posts_list',
  PaidPosts = 'paid_posts_list',
  AllPosts = 'all_posts_list',
  UnReadPosts = 'unread_posts_list',
  RelatedPosts = 'related_posts_list',
  ProfilePosts = 'profile_posts_list',
  PostDetail = 'post_detail_page',
  SearchResults = 'search_results_list',
  SpotlightUsers = 'spotlight_users_section',
  TopicPosts = 'topic_posts',
  LiveTopics = 'live_topics',
  BeginnerTopics = 'beginner_topics',
  TrendingPosts = 'trending_posts',
  HomeRecommendPosts = 'home_recommend_posts',
  SymbolRelatedPosts = 'symbol_related_posts_list',
  TrendingTopicPosts = 'hot_keyword_posts',
  InvestmentLevelPosts = 'investment_level_posts',
}

export interface PriceResponseResult {
  prices: {
    web_price: number;
    ios_price: number;
    android_price: number;
  };
}

export interface PriceListResponseResult {
  data: {
    web_amount: number;
    ios_amount: number;
    android_amount: number;
  }[];
}

export interface RedirectResponseData {
  data: {
    url: string;
  };
}

export interface NewRedirectResponseData {
  data: {
    code: string;
  };
}

export interface TippingPackage {
  ios_product_id: string;
  ios_product_name: string;
  android_product_id: string;
  android_product_name: string;
  web_product_name: string;
  web_amount: number;
  ios_amount: number;
  id: number;
  android_amount: number;
  currency: string;
}

export interface TippingPackagesResponseResult {
  data: {
    packages: TippingPackage[];
  };
}

export interface TippedInfoDetail {
  user: User | null;
  tipping_package: TippingPackage;
  post?: Post | null;
  type:
    | 'credit'
    | 'ios_iap'
    | 'android_iap'
    | 'web_coin'
    | 'android_coin'
    | 'ios_coin';
  created_at?: string;
}

export enum NotificationSettingType {
  LikePostNotification = 'disabled_like_post_notification',
  SharePostNotification = 'disabled_share_post_notification',
  ProfileViewNotification = 'disabled_profile_view_notification',
  TrendingPostNotification = 'disabled_trending_post_notification',
  VoteChartAlertNotification = 'disabled_vote_alert_notification',
  // PostAlertNotification = 'disabled_post_alert_notification',
  CommentRelatedNotification = 'disabled_comment_related_notification',
  FollowRelatedNotification = 'disabled_follow_related_notification',
  HostGoLiveNotification = 'disabled_host_go_live_notification',
  OtherNotification = 'disabled_other_notification',
  PostRelatedNotification = 'disabled_post_related_notification',
  InvitationRelatedNotification = 'disabled_invitation_related_notification',
  UserPredictionAlarmNotification = 'disabled_user_predict_alarm_notification',
  PredictionValueAlarmNotification = 'disabled_prediction_alarm_notification',
  IzanaviNotification = 'disabled_izanavi_notification',
}

export type NewsLettingSettingType =
  | 'creator_mail_notice'
  | 'event_mail_notice'
  | 'campaign_mail_notice'
  | 'news_letter';

export interface NotificationSetting {
  disabled_like_post_notification: boolean;
  disabled_share_post_notification: boolean;
  disabled_profile_view_notification: boolean;
  disabled_trending_post_notification: boolean;
  disabled_vote_alert_notification: boolean;
  disabled_post_alert_notification: boolean;
  disabled_comment_related_notification: boolean;
  disabled_follow_related_notification: boolean;
  disabled_host_go_live_notification: boolean;
  disabled_invitation_related_notification: boolean;
  disabled_other_notification: boolean;
  disabled_post_related_notification: boolean;
  disabled_user_predict_alarm_notification: boolean;
  disabled_prediction_alarm_notification: boolean;
  disabled_izanavi_notification: boolean;
}

export interface NewsletterSetting {
  creator_mail_notice?: boolean;
  event_mail_notice?: boolean;
  campaign_mail_notice?: boolean;
  news_letter?: boolean;
}

export interface TippedListPageResponseResult {
  data: TippedInfoDetail[];
}

export interface CreatorRanking {
  date: string;
  gain: number;
  percentage: number;
  ranking: number;
  total: number;
  user_info: User;
}

export interface TippedListResponseResult {
  data: {
    count: number;
    tipped_users: TippedInfoDetail[];
  };
}

export interface SymbolChart {
  active: boolean;
  ask: number;
  bid: number;
  change: number;
  code: string;
  highlight: boolean;
  id: number;
  last_ask: number;
  last_bid: number;
  last_price: number;
  last_volume: number;
  movement: string;
  name: string;
  price: number;
  type: string;
  volume: number;
  trading_economics_symbol_code: string;
  trading_economics_symbol_name: string;
  is_izanavi_supporting: boolean;
}

export interface SymbolListResult {
  data: SymbolChart[];
}

export enum LiveStreamStatus {
  InLive = 'in_live',
  BeforeLive = 'before_live',
  Ended = 'ended',
}

export enum LiveChatType {
  Chat = 'chat',
  Tip = 'tip',
}

export interface LiveStream {
  id: number;
  stream_id: string; //'3eaa7ea7-96a9-49ee-917c-227ced493755'
  user_id: number;
  post?: Post;
  status: LiveStreamStatus;
  started_at: string;
  delivery_started_at?: string;
  delivery_ended_at?: string;
  duration?: number;
  dvr_start_position?: number;
  ended_at: string;
  view_count: number;
  viewer_count: number;
  created_at: string;
  token: string;
  path_dash: string;
  path_hls: string;
  thumbnail?: string;
  streaming_key: LiveStreamKey;
  free_minutes: number;
  planned_to_start_at: string;
  video_source: StreamType;
  message_group?: {
    group_key: string;
    group_avatar?: string;
    group_name?: string;
    users?: User[];
  };
  statistic?: {
    id: number;
    view_count: number;
    current_viewer_count: number;
    viewer_count: number;
    status: LiveStreamStatus;
  };
}

export interface LiveStreamKey {
  id: number;
  name: string;
  stream_id: string;
  stream_url: string;
  user_id: number;
  created_at: string;
  available: boolean;
  streaming: LiveStream;
  webrtc_stream_url: string;
}

export interface Message {
  type: LiveChatType;
  avatar_url: string;
  user_id: number;
  username: string;
  name?: string;
  created_at: string;
  user_info?: User;
  message?: string;
  tip?: {
    id: number;
    amount: number;
  };
}

export interface GroupChatMessResult {
  data: {
    messages: Message[];
  };
}

export interface StreamRemainingTime {
  in_live_streamings: LiveStream[];
  max_count: number;
  count: number;
  free_post: {
    duration: number;
    remain_duration: number;
    max_duration: number;
  };
  paid_post: {
    duration: number;
    remain_duration: number;
    max_duration: number;
  };
}

export enum Period {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export enum TrendingTopicPeriod {
  LongTerm = 'long-term',
  MidTerm = 'mid-term',
  ShortTerm = 'short-term',
}

export interface VoteData {
  thumbs_down_percentage: number;
  thumbs_ups_percentage: number;
  total: number;
  percentage_change: number;
  type: Period;
}

export interface PostRate {
  type: Period;
  total: number;
  post_percentage: number;
}

export interface VoteSymbol {
  aggregate_vote_data: VoteData[];
  graph_vote_data: VoteGraphData[];
  id: number;
  name: string;
  highlight: boolean;
  thumbs_down: number;
  thumbs_ups: number;
  code: string;
  vote: 'up' | 'down';
}

export enum AbTestResult {
  A = 'A',
  Control = 'CONTROL',
}
