import { MembershipPackageName } from 'common/interfaces/api';

export const POLICY_URL = 'https://postprime.com/privacy_policy';
export const SECURITY_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/6275891615119';
export const USER_TERM_URL =
  'https://help.postprime.com/hc/ja/articles/4405379912207';
export const JUVELINE_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4403725850639';
export const POST_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4403551479183';
export const PRIME_POST_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4407618237327-%E3%83%97%E3%83%A9%E3%82%A4%E3%83%A0%E6%8A%95%E7%A8%BF%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC';
export const PAYMENT_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4403726198927';
export const AUTHOR_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4403725883279';
export const COMPANY_INFO_URL =
  'https://help.postprime.com/hc/ja/articles/4402870520079';
export const NEW_CONTACT_REQUEST_URL =
  'https://help.postprime.com/hc/ja/requests/new?ticket_form_id=360011808915';
export const VALID_PASSWORD_REGEX =
  /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@^_`{|}~]+$/;
export const NON_UPLOADING_PROGRESS = -1;
export const TAX_POLICY_URL =
  'https://help.postprime.com/hc/ja/articles/4404951201679';
export const PRIME_SUB_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4402703405967';
export const PAYMENT_RULE_URL =
  'https://help.postprime.com/hc/ja/articles/4402703405967-%E3%83%97%E3%83%A9%E3%82%A4%E3%83%A0%E7%99%BB%E9%8C%B2-%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB%E6%96%B9%E6%B3%95';
export const PER_PAGE = 100;
export const COMMENTS_PER_PAGE = 10;
export const CHART_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4685225563023';
export const BADGE_SYSTEM_URL =
  'https://help.postprime.com/hc/ja/articles/4402871073551-%E3%83%90%E3%83%83%E3%82%B8%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%81%A8%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6';
export const VARIOUS_POLICY_GUIDE_URL =
  'https://help.postprime.com/hc/ja/categories/4403585512719-%E5%90%84%E7%A8%AE%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC-%E3%82%AC%E3%82%A4%E3%83%89%E3%83%A9%E3%82%A4%E3%83%B3';
export const INVITE_USER_EVENT = 'introduction_reward_inviting';
export const INVITE_USER_COIN_EVENT = 'introduction_reward_inviting_coin';
export const INPUT_REFERRAL_CODE_EVENT = 'introduction_reward_token';
export const INPUT_REFERRAL_CODE_COIN_EVENT = 'introduction_reward_coin';
export const DEFAULT_AVATAR_URL = '/images/icons/default-avatar6.png';
export const FREE_TRIAL_GOLD_EVENT = 'free_trial_gold_member';
export const FREE_TRIAL_PLATINUM_EVENT = 'free_trial_platinum_member';
export const DISCOUNT_TO_100_YEN_SILVER_EVENT =
  'discount_to_100_yen_silver_membership';
export const COIN_BACK_EVENT = 'coin_after_subscribe_promotion';
export const TIP_POLICY =
  'https://help.postprime.com/hc/ja/articles/4413937890319';
export const HELP_CENTER_LINK = 'https://help.postprime.com/hc/ja';
export const INVITE_POLICY_LINK =
  'https://help.postprime.com/hc/ja/articles/4409178599567-%E6%8B%9B%E5%BE%85%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6';
export const COIN_POLICY =
  'https://help.postprime.com/hc/ja/articles/4591729750799';
export const MEMBERSHIP_POLICY_LINK =
  'https://help.postprime.com/hc/ja/articles/4911893133967';
export const MEMBERSHIP_BENEFITS_HELP_LINK =
  'https://help.postprime.com/hc/ja/articles/4859529332751#h_01H2A8CDQJHA60ZHB06AZBCMGY';
export const MEMBERSHIP_HELP_LINK =
  'https://help.postprime.com/hc/ja/articles/4859529332751-%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E7%99%BB%E9%8C%B2';
export const MEMBERSHIP_UPDOWN_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4859529332751-%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E7%99%BB%E9%8C%B2#h_01GB9N0DW5YRAKDP66AKKTHTT1';
export const INVESTMENT_VIOLATION_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4407604515343';
export const IG_MISSION_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/5569669037071#h_ig';
export const INVAST_MISSION_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/5569669037071#h_01HZ4DH706H1TBTY9P4NJMHXJR';
export const RENOSY_MISSION_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/5569669037071#h_01JFV98JA1C2AVS0SVBYGF4QJY';
export const PAYMENT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4402703264911';
export const STREAM_STATISTICS_INTERVAL = 10000;
export const CREATE_NEW_KEY = 'create_new_key';
export const PLAYER_RATES = [2, 1.5, 1];
export const MEMBERSHIP_PLAYER_RATES = [2, 1.75, 1.5, 1, 0.75, 0.5];
export const ALL_PLAYER_RATES = [
  {
    rate: 3,
    isVip: true,
    vipPackage: MembershipPackageName.Gold,
  },
  {
    rate: 2.75,
    isVip: true,
    vipPackage: MembershipPackageName.Gold,
  },
  {
    rate: 2.5,
    isVip: true,
    vipPackage: MembershipPackageName.Silver,
  },
  {
    rate: 2.25,
    isVip: true,
    vipPackage: MembershipPackageName.Silver,
  },
  {
    rate: 2,
    isVip: false,
  },
  {
    rate: 1.75,
    isVip: true,
    vipPackage: MembershipPackageName.Green,
  },
  {
    rate: 1.5,
    isVip: false,
  },
  {
    rate: 1.25,
    isVip: true,
    vipPackage: MembershipPackageName.Gold,
  },
  {
    rate: 1,
    isVip: false,
  },
  {
    rate: 0.75,
    isVip: true,
    vipPackage: MembershipPackageName.Green,
  },
  {
    rate: 0.5,
    isVip: true,
    vipPackage: MembershipPackageName.Green,
  },
  {
    rate: 0.25,
    isVip: true,
    vipPackage: MembershipPackageName.Silver,
  },
];

export const ALL_PLAYER_RATES_COURSE = [2, 1.75, 1.5, 1, 0.75, 0.5];

export const FREE_MINUTES1 = 10;
export const FREE_MINUTES2 = 20;
export const FREE_MINUTES3 = 30;
export const LIVE_HELP_LINK =
  'https://help.postprime.com/hc/ja/articles/4832924571279';
export const DEFAULT_TIME_HMS = '00:00';
export enum ReferralScreen {
  RecommendCreators = 'recommend_creators',
}
export const PP_EMAILS = [
  'usmarket@postprime.com',
  'japanmarket@postprime.com',
  'takahashidan589@gmail.com',
];
export const AUDIO_TMP_URL = 'data:audio/mp3;base64,test';
export const FREE_MINUTES_OPTIONS = [0, 3, 5, 10, 15, 20, 30];
export const TRACKING_EVENTS_KEY_NAME = 'PostPrimeTracking';
export const MAX_TRACKING_EVENTS_COUNT = 30;
export const DONT_OPEN_CHART_DES_NEXTIME = 'PostPrimeHideChartDescription';
export const FAQ_URL =
  'https://help.postprime.com/hc/ja/articles/4403725883279-%E8%91%97%E4%BD%9C%E6%A8%A9%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC#h_01FMKZ4JEAXS4GE79VRZ0ZTBQ5';
export const CURRENT_BADGE_INFO_KEY_NAME = 'pp_current_badge_info';
export const CURRENT_CHARACTER_INFO_KEY_NAME = 'pp_current_character_info';
export const COURSE_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/6807439255951';
export const NEW_INVOICE_SYSTEM_URL =
  'https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice.htm';
export const PLATINUM_MEMBERSHIP_EVENT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4591802783759';
export const ANIME_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/7315605694351';
export const COIN_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/6638679636623';
export const COIN_BACK_EVENT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/8007606200079';
export const PRIME_AND_MEMBERSHIP_DIFF_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/8690762667407-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F-%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E7%99%BB%E9%8C%B2%E3%81%A8%E3%83%97%E3%83%A9%E3%82%A4%E3%83%A0%E7%99%BB%E9%8C%B2%E3%81%AE%E9%81%95%E3%81%84';
export const PRIME_PLUS_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/9739484411279-%E3%83%97%E3%83%A9%E3%82%A4%E3%83%A0Plus%E3%81%A8%E3%81%AF';
export const POST_TEXT_MAX_LENGTH = 3000;
export const IG_20000_COIN_EVENT = 'ig_account_special_register';
export const YEAR_END_2024_EVENT = 'year_end_2024_prime_subscription';
export const YEAR_END_2024_EVENT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/11247605278095';
export const BENEFIT_INPUT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4403155587983#h_01JBBMZ91YAVNJK309CKGMC4YZ';
export const YEAR_START_2025_EVENT = 'year_end_2024_membership';
export const YEAR_START_2025_EVENT_HELP_URL =
  'https://help.postprime.com/hc/ja/articles/4865466853903';
export const RENOSY_10000_COIN_EVENT = 'renosy_account_special_register';
export const EVENT_202507_URL = 'https://forms.gle/ZaHScKjyHun4NMPp9';
export const EVENT_202507_EVENT = '2025_july_event_v2';
