import cn from 'classnames';
import Image from 'next/image';
import { Emotion, PostActionEmotion, User } from 'common/interfaces/api';
import { DEFAULT_AVATAR_URL } from 'common/utils';
import { useRouter } from 'next/router';
import styles from './RoundIcon.module.scss';

export type Props = {
  user: User | null;
  width: number;
  height: number;
  alt?: string;
  emotion?: Emotion | PostActionEmotion;
  hasRing?: boolean;
  hasLightDot?: boolean;
  enableClick?: boolean;
  // these events are needed don't delete
  showAddBtn?: boolean;
  emotionSize?: number;
  showLiveFlag?: boolean;
  onClick?: () => void;
};

export default function RoundIcon({
  user,
  width,
  height,
  emotion,
  hasRing = false,
  enableClick = false,
  showAddBtn = false,
  hasLightDot = false,
  emotionSize,
  showLiveFlag = false,
  onClick,
}: Props): JSX.Element {
  const src = user?.avatar_url || DEFAULT_AVATAR_URL;
  const isOnline = !!user?.has_new_post;
  const link = `/${user?.username}`;
  const router = useRouter();
  const hasTippedId = user?.has_tipped || 0;
  const showingLive = showLiveFlag && user?.in_live && width >= 44;
  const badgeEl = (emotion || showAddBtn) && (
    <div className={styles.badge}>
      <Image
        src={getEmotionIcon(emotion)}
        width={emotionSize ? emotionSize : width * 0.43}
        height={emotionSize ? emotionSize : height * 0.43}
        alt="emotion"
      />
    </div>
  );
  const content = (
    <div
      className={cn(styles.iconWrapper, {
        [styles.ring]: hasRing && (isOnline || !!hasTippedId || showingLive),
        [styles.online]: isOnline,
        [styles[`tip-${hasTippedId}`]]: hasRing && !!hasTippedId,
        [styles.redRing]: hasRing && showingLive,
      })}
      style={{ width, height }}
    >
      <div className={styles.roundWrapper}>
        <img src={src} width={width} height={height} loading="lazy" />
      </div>
      {hasLightDot && <span className={styles.lightDot}></span>}
      {showingLive ? (
        <span
          className={cn(styles.liveFlag, {
            [styles.md]: width > 60 && width <= 80,
            [styles.lg]: width > 80,
          })}
          style={{ top: `${height - 5}px` }}
        >
          LIVE
        </span>
      ) : (
        badgeEl
      )}
    </div>
  );
  if (enableClick) {
    return (
      <a
        onClick={() => {
          void router.push(link);
          onClick && onClick();
        }}
        style={{ cursor: 'pointer' }}
      >
        {content}
      </a>
    );
  }
  return content;
}

const getEmotionIcon = (emotion: Emotion | PostActionEmotion) => {
  if (!emotion) return `/images/icons/emotion-add.svg`;
  // PostActionEmotion.InvitationCodeReferred
  if (emotion === PostActionEmotion.InvitationCodeReferred) {
    return '/images/custom_icons/emotion-invitation-code-referred.png';
  }
  return `/images/icons/emotion-${emotion}.svg`;
};
